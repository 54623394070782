<template>
    <div class="playerfavorite_container">
        <alert-modal
            v-if="isAlertModal"
            :alertText="`선호음악은 최대 10개까지 선택가능합니다.`"
            @closeModal="onCloseAlertModal()"
        />
        <video id="previewvideo" hidden autoplay playsinline></video>
        <confirm-comp
            id="playStop"
            :text1="'재생중이 음악이 있을 경우,'"
            :text2="'미리듣기를 이용할 수 없습니다.'"
            :text3="'재생중인 음악을 중지해드릴까요?'"
            v-if="isPlayStop"
            @close-modal="onCloseModal"
            @confirm-modal="onConfirmModal"
        />
        <confirm-comp
            id="autoRecomm"
            :text1="'선호자동추천으로 변경하시면 선택하신'"
            :text2="'‘음악‘ 정보가 모두 선택 해제됩니다.'"
            :text3="'선택하신 음악으로 계속 하시겠습니까?'"
            @close-modal="onAutoRecommCloseModal"
            @confirm-modal="onAutoRecommConfirmModal"
            v-if="isAutoRecomm"
        />
        <div class="playerfavorite_wrap">
            <div class="playerfavorite_header dp_flex flex_space_between align_center">
                <h2 class="playerfavorite_title">선호 음악 선택</h2>
                <img
                    class="playerfavorite_close cursor"
                    @click="$emit('close-favoritemodal')"
                    src="/media/img/close_icon_btn_black.png"
                    alt="닫기"
                />
            </div>
            <div class="playerfavorite_body">
                <favorite-loading v-if="isLoading" :ispopup="true"></favorite-loading>
                <ul v-else class="playerfavorite_musiclist_wrap">
                    <li
                        class="music_item"
                        v-for="(item, index) in favoiteList"
                        :key="item.musicId"
                        :class="{ first: (index + 1) % 5 === 1, last: (index + 1) % 5 === 0 }"
                    >
                        <figure :id="'previewmusic' + index">
                            <img
                                class="music_img  cursor"
                                @mouseenter.prevent="onPreviewMouseEnter(index, $event)"
                                :src="item.imgUrl"
                                :alt="item.musicNm"
                            />
                            <div
                                v-if="selectPreferMusic.includes(item.musicId)"
                                class="favorite_active"
                                @mouseenter.prevent="onPreviewMouseEnter(index, $event)"
                            >
                                <img class="favorite_hart" src="/media/img/space/hart.png" alt="좋아요" />
                            </div>
                        </figure>
                        <transition name="fade">
                            <div
                                v-if="item.isActive"
                                class="favorite_hover cursor"
                                @click="onClickSelectPrefer(item, $event)"
                                @mouseleave.prevent="onPreviewMouseLeave(index, $event)"
                            >
                                <figure id="playBtnWrap" class="preview_btn" v-if="!item.isPlay">
                                    <img
                                        id="playBtn"
                                        class="preview_btnimg"
                                        src="/media/img/play_btn.png"
                                        @click.prevent="onMusicPlay(index)"
                                        alt="플레이버튼"
                                    />
                                </figure>
                                <div id="rythmWrap" class="rythm_wrap" v-if="item.isPlay" @click.prevent="onMusicPlay(index)">
                                    <ul id="rythmItem" class="rythm cursor">
                                        <li id="rythmItem1" class="rythm_item"></li>
                                        <li id="rythmItem2" class="rythm_item second"></li>
                                        <li id="rythmItem3" class="rythm_item last"></li>
                                    </ul>
                                </div>
                            </div>
                        </transition>

                        <span class="music_songtitle">{{ item.musicNm }}</span>
                        <span class="music_songartist">{{ item.artist }}</span>
                    </li>
                </ul>
            </div>
            <ul class="playerfavorite_footer dp_flex flex_space_between align_center">
                <li>
                    <button class="auto_recommbtn" :disabled="autoRecommBtnDisabled" @click="onAutoRecommend">
                        <img class="auto_recommbtn_img" src="/media/img/space/space_light.png" alt="자동추천" />
                        자동 추천 받을래요.
                    </button>
                </li>
                <li class="dp_flex">
                    <button class="playerfavorite_btn" @click="$emit('close-favoritemodal')">취소</button>
                    <button class="playerfavorite_btn confirm" @click="onClickConfirm" :disabled="btnDisabled">완료</button>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { getPreferMusicList, selectSimilarMusicList } from '@/service/api/profileApi';
import { defineComponent } from '@vue/composition-api';
import Hls from 'hls.js';
export default defineComponent({
  name: 'PlayerFavorite',
  emits: ['close-favoritemodal', 'confirm-modal'],
  props: {
    curationid: {
      type: String,
      default: '',
      required: false
    },
    spaceid: {
      type: String,
      default: '',
      required: false
    }
  },
  components: {
    'confirm-comp': () => import(`@/components/modal/Space/ConfirmComp.vue`),
    'favorite-loading': () => import(`@/components/space/FavoriteLoading.vue`),
    'alert-modal': () => import(`@/components/modal/Space/AlertModal.vue`)
  },
  data () {
    return {
      favoiteList: [],
      previewvideo: null,
      config: {
        enableWorker: true,
        lowLatencyMode: true,
        backBufferLength: 90
      },
      isPlayStop: false,
      isAutoRecomm: false,
      selectItem: null,
      selectPreferMusic: [],
      checkAutoRecomm: false,
      autoRecommBtnDisabled: false,
      isLoading: false,
      isAlertModal: false
    };
  },

  created () {
    this.setPreferMusicList();
  },

  mounted () {
    this.setPreviewVideo();
  },

  methods: {
    onCloseAlertModal () {
      this.isAlertModal = false;
    },
    async onClickConfirm () {
      this.autoRecommBtnDisabled = true;
      this.bottomBtnDisabled = true;
      if (this.checkAutoRecomm) {
        this.$emit('confirm-modal', this.checkAutoRecomm, [], true);
      } else {
        this.$emit('confirm-modal', this.checkAutoRecomm, this.selectPreferMusic, true);
      }
    },
    onAutoRecommend () {
      if (this.selectPreferMusic.length > 0) {
        this.isAutoRecomm = true;
      } else {
        //
        this.onAutoRecommConfirmModal();
      }
    },
    onAutoRecommCloseModal () {
      this.isAutoRecomm = false;
    },
    onAutoRecommConfirmModal () {
      this.isAutoRecomm = false;
      this.selectPreferMusic = [];
      this.checkAutoRecomm = true;

      this.onClickConfirm();
    },
    setPreviewVideo () {
      this.previewvideo = document.querySelector('#previewvideo');
      if (Hls.isSupported()) {
        this.hls = new Hls(this.config);
      }
      this.previewvideo.addEventListener('ended', this.onVideoEnded);
    },
    onVideoEnded () {
      this.resetPlayer();
    },
    resetPlayer (musicId = null) {
      const len = this.favoiteList.length;
      for (let i = 0; i < len; i++) {
        const { musicId: diffMusicId } = this.favoiteList[i];
        if (musicId && diffMusicId === musicId) {
          continue;
        }
        this.favoiteList[i].isPlay = false;
        this.favoiteList[i].isActive = false;
      }
    },

    getMusicPath (path) {
      const musicPath =
        window.location.host.indexOf('localhost') !== -1
          ? `http://13.209.152.125:10084/stream/getMusic?${path}`
          : `stream/getMusic?${path}`;

      return musicPath;
    },
    setMusic (musicPath) {
      if (Hls.isSupported()) {
        this.hls.loadSource(`${musicPath}`);
        this.hls.attachMedia(this.previewvideo);
      }
    },
    onCloseModal () {
      this.isPlayStop = false;
      this.selectItem = null;
    },
    onConfirmModal () {
      this.$VideoPlayer.onPause();
      const { musicId } = this.selectItem;
      // 켜져있는 것 종료
      this.resetPlayer(musicId);
      // 재생 처리
      this.isPlayStop = false;
      const len = this.favoiteList.length;
      for (let i = 0; i < len; i++) {
        const findIdx = this.favoiteList.findIndex(item => item.musicId === musicId);
        if (findIdx > -1) {
          const { musicId } = this.favoiteList[findIdx];
          this.favoiteList[findIdx].isActive = true;
          this.favoiteList[findIdx].isPlay = true;
          const musicPath = this.getMusicPath(musicId);
          this.setMusic(musicPath);

          this.previewvideo.play().catch(() => {
            this.favoiteList[findIdx].isPlay = false;
          });

          break;
        }
      }
    },
    onMusicPlay (index) {
      if (this.favoiteList[index]) {
        const { musicId, isPlay } = this.favoiteList[index];
        if (isPlay) {
          this.favoiteList[index].isPlay = false;
          this.favoiteList[index].isActive = false;
          this.favoiteList[index].longTouchToggle = false;
          this.previewvideo.pause();
        } else {
          if (this.$store.state.isPlay) {
            this.selectItem = this.favoiteList[index];
            // 플레이 모달 띄우기
            this.isPlayStop = true;
          } else {
            // 켜져있는 것 종료
            this.resetPlayer(musicId);
            this.favoiteList[index].isPlay = true;
            const musicPath = this.getMusicPath(musicId);
            this.setMusic(musicPath);

            this.setMusic(musicPath);

            this.previewvideo.play().catch(() => {
              this.favoiteList[index].isPlay = false;
            });
          }
        }
      }
    },
    setPreferMusicList () {
      this.isLoading = true;
      getPreferMusicList(this.curationid)
        .then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            const len = result.length;
            for (let i = 0; i < len; i++) {
              result[i].isActive = false;
              result[i].isPlay = false;
              result[i].imgUrl = `${process.env.VUE_APP_BASE_URL}${result[i].albumImg}`;
              result[i].longTouchToggle = false;
              result[i].touchstarttime = null;
              result[i].isNotRecommendSong = true;
              result[i].isCheckRecommendSong = false;

              this.favoiteList.push(result[i]);
            }
          } else {
            this.favoiteList = [];
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onPreviewMouseEnter (index) {
      const list = this.resetFavoriteList();
      this.favoiteList = list;
      if (this.favoiteList[index]) {
        this.favoiteList[index].isActive = true;
      }
    },

    resetFavoriteList () {
      return this.favoiteList.map(item => {
        return {
          ...item,
          isActive: !!item.isPlay
        };
      });
    },
    onPreviewMouseLeave (index) {
      if (this.favoiteList[index]) {
        if (!this.favoiteList[index].isPlay) {
          this.favoiteList[index].isActive = false;
        }
      }
    },
    getFavorite (result = []) {
      const newArray = [];
      const len = result.length;
      for (let i = 0; i < len; i++) {
        result[i].isActive = false;
        result[i].isPlay = false;
        result[i].imgUrl = `${process.env.VUE_APP_BASE_URL}${result[i].albumImg}`;
        result[i].longTouchToggle = false;
        result[i].touchstarttime = null;
        result[i].isNotRecommendSong = false;
        result[i].isCheckRecommendSong = false;

        newArray.push(result[i]);
      }

      return newArray;
    },
    onClickSelectPrefer (paramItem, $event) {
      if ($event) {
        const { id } = $event.target;
        if (id === 'playBtn' || id === 'rythmWrap' || id.includes('rythmItem')) return;
      }
      const { musicId, isNotRecommendSong, isCheckRecommendSong } = paramItem;
      const findIdx = this.selectPreferMusic.findIndex(item => item === musicId);
      if (findIdx > -1) {
        this.selectPreferMusic.splice(findIdx, 1);
      } else {
        if (this.selectPreferMusic.length >= 10) {
          this.isAlertModal = true;
          return true;
        }
        this.selectPreferMusic.push(musicId);
        const musicIdList = this.favoiteList.map(item => item.musicId).join(',');
        if (isNotRecommendSong && !isCheckRecommendSong) {
          paramItem.isCheckRecommendSong = true;
          selectSimilarMusicList(this.spaceid, musicId, musicIdList).then(res => {
            const { resultCd, result } = res.data;
            if (resultCd === '0000') {
              const returnList = this.getFavorite(result);
              const findIndex = this.favoiteList.findIndex(item => item.musicId === musicId);
              const firstSlice = this.favoiteList.slice(0, findIndex + 1);
              const lastSlice = this.favoiteList.slice(findIndex + 1);
              this.favoiteList = [...firstSlice, ...returnList, ...lastSlice];
            }
          });
        }
      }
    }
  },
  computed: {
    btnDisabled () {
      if (this.bottomBtnDisabled) return true;

      if (this.checkAutoRecomm) {
        return false;
      } else {
        if (this.selectPreferMusic.length >= 3 && this.selectPreferMusic.length <= 11) {
          return false;
        }
        return true;
      }
    }
  }
});
</script>
<style scoped src="@/assets/css/player/playerfavorite.css"></style>
